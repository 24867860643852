@import "../../../../_theme_variables";

#tmsearch {
	/*
	width:100%;
	clear:both;
	*/
	padding:0;
	#tmsearchbox {
		float: left;
		width: 100%;
		position:relative;
		background: #f2f2f2;
		padding-right:53px;
		padding-left:30px;
	}
	.btn.button-search {
		background: none;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		border: none;
		width: 53px;
		padding: 0;
		height:40px;
		color:#aeaeae;
		@include transition (.3s);
		span {
			display: none;
		}
		&:before {
			content: "\e13d";
			font-family: $flat-icon;
			display: block;
			font-size: 22px;
		}
		&:hover {
			color: #333;
		}
	}
	#tm_search_query {
		display: inline-block;
		padding: 9px 0;
		height:40px;
		border:none;
		background:none;
	}
}

body .ac_results {
	background: #f2f2f2;
	border: none;
	width: 300px !important;
	margin-top: 0px;
	z-index:9;
	left:0 !important;
	@media (max-width: $screen-md-max) { // max 1199px
		width: 280px !important;
	}
		li {
			padding: 5px;
			font-weight: normal;
			color: #686666;
			font-size: 13px;
			line-height: 22px;
			cursor:pointer;
			&.ac_odd {
				background: white;
			}
   			&:hover, &.ac_over {
      			background: #fbfbfb;
			}
		}
	  	.pull-left {
			margin-right:20px;
		}
		.content {
			overflow:hidden;	
		}
	  	span.reference, span.manufacturer {
			display:block;
			margin:0 0 5px;
			font-size:12px;
			color:#3a3939;
			line-height:15px;
		}
		span.product-name {
			display:inline-block;
			font: 300 16px / 22px $font-family;
			padding-bottom:10px;
			padding-top:10px;
		}
		strong {
			color:#f00;
			font-weight:normal;
		}
		.description {
			font-size:11px;
			line-height:15px;	
		}
		.price {
			padding-right:5px;
			&.old-price {
				font-family:$font-family;
				font-weight:300;
				font-size:13px;
			}
		}
		@media only screen and (min-width:768px) and (max-width:991px) {
			.content {
				float:left;
				clear:both;	
			}
		}
}

form#tmsearchbox {
	position: relative;
	label {
		color: #333333; 
	}
	input#search_query_block {
		margin-right: 10px;
		max-width: 222px;
		margin-bottom: 10px;
		display: inline-block;
		float: left; 
	}
	.btn.btn-sm {
    	float: left;
		i {
      		margin-right: 0; 
		}
	}
}